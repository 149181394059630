const mobileApp = () => {
  let elements = document.querySelectorAll('.paralax_mobile');

  let observer = new IntersectionObserver(
    function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.5 }
  );

  elements.forEach(function (element) {
    observer.observe(element);
  });
};
document.addEventListener('DOMContentLoaded', async () => {
  await mobileApp();
});

